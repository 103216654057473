var Otto = Otto || {};

/**
 * Provide carousel functionality.  Module is based on a
 * 3 image size system, with breakpoints provided for each
 * window size specification.
 *
 * DEPENDENCIES: This module requires jquery and slick to support
 * image slideshow functionality.
 */

Otto.carousel = (function(my, $) {
    var exports = {},
        $carouselEl,        // jQuery target element
        pathsSm = [],       // array of images for small screens
        pathsMd = [],       // array of images for medium screens
        pathsLg = [],       // array of images for large screens
        itemClass = null,   // class applied to li items
        captions = [],      // captions for images
        slideLinks = [],    // if slides are clickable, links they go to
        captionClass = null,
        currSize = null,
        sizeBreakPts = null,
        initd = false,      // is slideshow initialized?

        SMALL = 'sm',       // Set some 'constants' for size reference
        MEDIUM = 'md',
        LARGE = 'lg';

    // Process data provided within markup.  $data represents jQuery
    // object tied to dom UL element storing image data.
    function processData($data) {
        sizeBreakPts = {};

        // Fill in size breakpoint data
        var sizes,
            index = 0,
            sizeKeys = [SMALL,MEDIUM,LARGE];
        if ($data.data('sizes')) {
            sizes = $data.data('sizes').split(',');
            for (index = 0; index < sizes.length; index++) {
                sizes[index] = parseInt(sizes[index], 10);
            }
        } else {
            sizes = [768,1000,1350];
        }

        for (index = 0; index < sizes.length; index++) {
            sizeBreakPts[sizeKeys[index]] = parseInt(sizes[index], 10);
        }

        // Process image paths
        var pathItems = $data.find('img');
        $.each(pathItems, function(index, el) {
            var $item = $(el);
            pathsSm.push($item.data('img-sm'));
            pathsMd.push($item.data('img-md'));
            pathsLg.push($item.data('img-lg'));
            captions.push($item.data('caption'));
            slideLinks.push($item.data('slide-link'));
        });

    }

    function getSize() {
        var contSize = $(window).width();

        if (contSize >= sizeBreakPts[LARGE]) {
            return LARGE;
        } else if (contSize >= sizeBreakPts[MEDIUM]) {
            return MEDIUM;
        } else {
            return SMALL;
        }

    }


    function checkForSizeChange() {

        // If window width setting is the same, do nothing.  We
        // support 3 different sizes (small, medium, and large).
        // If we move from one size to the other, we need to
        // adjust the slideshow.
        var winSize = getSize();
        if (winSize === currSize) return;

        // Otherwise, load image in corresponding size
        currSize = winSize;
        setupSlideshow();
    }

    // By specifying window size, we return set of images
    // optimized for this window
    function getImgArray(size) {
        var srcArr = null;
        switch (size) {
            case SMALL:
                return pathsSm.slice(0);
            case MEDIUM:
                return pathsMd.slice(0);
            case LARGE:
                return pathsLg.slice(0);
        }
    }

    function setupSlideshow() {
        var startIndex = 0,
            imgArr = null,
            carouselItems = null;

        if (initd) {
            // Need to kill slideshow first...
            startIndex = $carouselEl.slick('slickCurrentSlide');
            $carouselEl.slick('unslick');
        }

        // Get correct image set per screen size
        imgArr = getImgArray(currSize);

        // Cycle through img elements and fill in the data-lazy
        // attribute with the correct paths.  Slick will use
        // these values for populating slider using lazy
        // loading technique.  Note that we remove the li
        // elements first, to remove any vestigle items
        // slick may have left behind if this is a refresh
        // of image sizes.  We then add them back in as we
        // assign the lazy load paths on each img element.
        carouselItems = $carouselEl.find('div');
        carouselItems.remove();
        $.each(imgArr, function(index, el) {
            var mu = "",
                $item = null,
                caption = captions[index],
                slideLink = slideLinks[index],
                aTagClose = '';

            mu =  '<div class="' + itemClass + '">';
            if (slideLink !== null) {
                mu += '<a href="' + slideLink + '">';
                aTagClose = '</a>';
            } else {
                aTagClose = '';
            }
            mu += '<img data-lazy="' + el + '">' + aTagClose;
            mu += '<div class="' + captionClass + '"><span>';
            mu += caption + '</span></div></div>';
            $item = $(mu);
            $carouselEl.append($item);
        });

        // TODO: need to get ajax image loader placed properly
        $carouselEl.slick({
            lazyLoad: 'progressive',
            initialSlide: startIndex,
            adaptiveHeight: true,
            speed:180
        });


        initd = true;
    }

    function wireEvents() {
        $(window).resize(function(evt){
            checkForSizeChange();
        });
    }

    // Note: Parameters specified here are to be sent as strings.
    // Provide a target dom UL element.
    exports.init = function(carouselEl, iClass, capClass) {
        $carouselEl = $(carouselEl);
        itemClass = iClass;
        captionClass = capClass;

        // Check to see if there is an actual photo set to process,
        // and target element to fill.
        if ($carouselEl.length === 0) return;

        // Process data found in our markup
        processData($carouselEl);

        // Wire up our events
        wireEvents();

        // Get current size, based on window size
        currSize = getSize();

        // And start up the slideshow...
        setupSlideshow();

    };

    return exports;
})(Otto, jQuery);



/**
 * Main Site Script - Entry point for application
 *
 * This script is loaded and run on every page.  Global code resides
 * here, and we also initialize any page-specific code, via our
 * Otto.page object (loaded by the individual 'page' scripts, determined
 * by which page or template we're loading).
 */

var Otto = Otto || {};



/**
 * Here we handle the main site/application initialization.
 */
Otto.main = (function(my, $) {
    // Create object for storing our methods for public consumption
    // (this sets up our external API for this module)
    var exports = {};

    exports.init = function() {
        if (my.hasOwnProperty("header")) my.header.init();
        if (my.hasOwnProperty("footer")) my.footer.init();
        if (my.hasOwnProperty("page")) my.page.init();

        // Clear out the preload script, used to prevent initial
        // css transitions.
        $('body').removeClass('is-preload');

        // Let's also prep any hash links that are meant to smooth scroll
        $('.smooth-scroll').on('click', function(event) {
            event.preventDefault();
            var linkTarget = $(event.target).attr('href');
            $(window).scrollTo(linkTarget, 750, 'swing');
        });
    };

    return exports;
})(Otto, jQuery);


$(function(){
    if (Otto.hasOwnProperty("main")) Otto.main.init();
});
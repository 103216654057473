var Otto = Otto || {};

/**
 * Here we define some helper functions for global use.
 */
Otto.util = (function(my, $) {
    // Create object for storing our methods for public consumption
    // (this sets up our external API for this module)
    var exports = {};
    var mqMobile = window.matchMedia('(max-width: 767px)');
    var mqDesktop = window.matchMedia('(min-width: 1024px)');

    // Crude script for mobile determination.  Our CSS mobile/tablet
    // determination is based on screen width, so we use the same
    // method here.
    exports.isMobile = function () {
        if (mqMobile.matches) {
            return true;
        }
        return false;
    };
    // Crude script for desktop determination.  Our CSS mobile/tablet
    // determination is based on screen width, so we use the same
    // method here.  NOTE: we include table horizontal size in
    // this calculation, as its layouts are the same as desktop.
    exports.isDesktop = function() {
        if (mqDesktop.matches) {
            return true;
        }
        return false;
    };

    // Helper function to add 'days' number of days to a date
    // object.  New date object is returned.
    exports.addDays = function (date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };

    return exports;
})(Otto, jQuery);


// Protect against console calls on old browsers
if (window.console === undefined) {
    window.console = {};
    window.console.log = function() {};
}
var Otto = Otto || {};

/**
 * Manage our footer module
 */
Otto.footer = (function(my, $) {
    var exports = {},
        $windowRef      = $(window),
        $footerRef      = $('#footer'),
        footerVis       = false;   // If the footer is in the window

    function wireEvents() {
        $(window).scroll(function (evt) {
            calcPosition();
        });
        // Let's listen for changes in body size as well (dispatched
        // by some pages when body content size changes, eg the 
        // locations page)
        $('body').on('bodySizeChange', calcPosition);
    }

    function calcPosition() {
        var footerIsVisible = ($windowRef.scrollTop() + $windowRef.height() > $footerRef.offset().top);
        if (footerVis != footerIsVisible) {
            // Send out a dispatch...
            footerVis = footerIsVisible;
            var evt = $.Event( "footerVisChange", { isFooterVisible: footerVis } );
            $('body').trigger(evt);
        }
    }

    exports.init = function () {
        wireEvents();
        calcPosition();
    };
    exports.isFooterVisible = function() {
        return footerVis;
    };

    return exports;
})(Otto, jQuery);
